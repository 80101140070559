import { useState } from "react";
import axios from "axios";

const useFetchSsoTokenLearning = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );

    const FetchSsoTokenLearning = async ( refreshToken, clientId ) => {
        setIsLoading( true );
        const BASE_URL = 'https://auth.strideahead.in/sso';

        const requestBody = {
            client_id: clientId,
            type : 'lms',
        }

        try {
            const res = await axios.post( BASE_URL, requestBody, {
                headers: {
                    refresh_token: refreshToken
                }
            } );
            setResponse( res );
            setError( null );
        } catch ( err ) {
            setError( err );
            console.log( err );
        } finally {
            setIsLoading( false );
        }
    };

    return { response, error, isLoading, FetchSsoTokenLearning };
};

export default useFetchSsoTokenLearning;