import actions from './actions';

const { updateClientConfigBegin, updateClientConfigSuccess, updateClientConfigErr } = actions;

const updateClientConfig = (data) => {
    return async(dispatch) => {
        try {
            dispatch(updateClientConfigBegin());

            dispatch(updateClientConfigSuccess(data));
        } catch (err) {
            dispatch(updateClientConfigErr(err));
        }
    };
};

export { updateClientConfig };