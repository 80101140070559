import { useState } from "react";
import axios from "axios";
import { notification } from "antd";
import { FETCH_USER_INFO } from "../utility/constants";

const useFetchUserInfo = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );

    const FetchUserInfo = async ( refreshToken ) => {

        setIsLoading( true );

        try {
            const res = await axios.get( `${ FETCH_USER_INFO }`, {
                headers: {
                    refresh_token: refreshToken
                }
            } );
            setResponse( res );
            setError( null );
        } catch ( err ) {
            setError( err );
            notification.error( {
                message: 'Oops!',
                description: 'Please try again.',
            } )
        } finally {
            setIsLoading( false );
        }
    };

    return { response, error, isLoading, FetchUserInfo };
};

export default useFetchUserInfo;
