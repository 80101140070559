import actions from './actions'; // Import the default export

const initialState = {
    "primary_color": "#3F52FD",
    "secondary_color": "#3F52FD",
    "tertiary_color": "#3F52FD",
    "logo": "https://i.ibb.co/kXn3Y9c/logo.png",
    "email_provider": 0,
    "payment_provider": 0,
    "tag_line": "Stride Ahead",
    "favicon": "https://i.ibb.co/0BM2P4m/fevicon.png",
    "description": "Our assessment platform empowers future leaders with personalized guidance. Discover your strengths and areas for growth with. Unlock your potential today.",
    "id": 10,
    "client_id": '',
};

const { UPDATE_CLIENT_CONFIG_BEGIN, UPDATE_CLIENT_CONFIG_SUCCESS, UPDATE_CLIENT_CONFIG_ERR } = actions;

const clientConfigReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case UPDATE_CLIENT_CONFIG_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_CLIENT_CONFIG_SUCCESS:
            return {
                ...state,
                ...data,
                loading: false,
            };
        case UPDATE_CLIENT_CONFIG_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

export default clientConfigReducer;