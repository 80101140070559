import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { ReactReduxFirebaseProvider, isLoaded } from 'react-redux-firebase';
import { ConfigProvider, Spin } from 'antd';
import Cookies from 'js-cookie';
import store, { rrfProps } from './redux/store';
import Admin from './routes/admin';
import Auth from './routes/auth';
import './static/css/style.css';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import 'antd/dist/antd.less';

const { theme } = config;

const ProviderConfig = () => {
    const { rtl, isLoggedIn, topMenu, darkMode, auth } = useSelector( state => ( {
        darkMode: state.ChangeLayoutMode.data,
        rtl: state.ChangeLayoutMode.rtlData,
        topMenu: state.ChangeLayoutMode.topMenu,
        isLoggedIn: state.auth.login,
        auth: state.fb.auth,
    } ) );

    const [path, setPath] = useState( window.location.pathname );

    useEffect( () => {
        let unmounted = false;
        if ( !unmounted ) {
            setPath( window.location.pathname );
        }
        // eslint-disable-next-line no-return-assign
        return () => ( unmounted = true );
    }, [setPath] );

    useEffect( () => {
        // Destructure hostname from window.location
        const { href: currentUrl, hostname } = window.location;

        // Extract the domain by removing the subdomain
        const domainParts = hostname.split( '.' );
        const domain = domainParts.length > 2
            ? `.${ domainParts.slice( -2 ).join( '.' ) }`
            : `.${ hostname }`;

        // Set the redirect_to cookie with the dynamic domain
        Cookies.set( 'redirect_to', currentUrl, { domain } );
    }, [] );


    return (
        <ConfigProvider direction={ rtl ? 'rtl' : 'ltr' }>
            <ThemeProvider theme={ { ...theme, rtl, topMenu, darkMode } }>
                <ReactReduxFirebaseProvider { ...rrfProps }>
                    { !isLoaded( auth ) ? (
                        <div className="spin">
                            <Spin />
                        </div>
                    ) : (
                            <Router basename={ process.env.PUBLIC_URL }>
                                { !isLoggedIn ? (
                                    <>
                                        <Route exact path="/" render={ () => <Redirect to="/stride" /> } />
                                        <Route path="/:client_name" component={ Auth } />
                                    </>
                                ) : (
                                    <ProtectedRoute path="/admin" component={ Admin } />
                                ) }
                                { isLoggedIn && ( path === process.env.PUBLIC_URL || path === `${ process.env.PUBLIC_URL }/` ) && (
                                    <Redirect to="/admin" />
                                ) }
                            </Router>
                    ) }
                </ReactReduxFirebaseProvider>
            </ThemeProvider>
        </ConfigProvider>
    );
}

function App() {
    return (
        <Provider store={ store }>
            <ProviderConfig />
        </Provider>
    );
}

export default hot( App );
