import React from 'react';
import {  FullPageGradient } from './overview/style';
// import Heading from '../../../components/heading/heading';
// import { FullPageGradient } from './overview/style';

const AuthLayout = ( WraperContent ) => {
  return function () {
    return (
      <FullPageGradient>
        <WraperContent />
      </FullPageGradient>
    );
  };
};

export default AuthLayout;
