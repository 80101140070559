import { useState } from "react";
import axios from "axios";
import { BASE_URL, FETCH_CLIENT_CONFIG } from "../utility/constants";

const useFetchClientConfig = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );

    const FetchClientConfig = async ( refreshToken ) => {

        setIsLoading( true );

        try {
            const res = await axios.get( `${ BASE_URL }${ FETCH_CLIENT_CONFIG }`, {
                headers: {
                    refresh_token: refreshToken
                }
            } );
            setResponse( res );
            setError( null );
        } catch ( err ) {
            setError( err );
        } finally {
            setIsLoading( false );
        }
    };

    return { response, error, isLoading, FetchClientConfig };
};

export default useFetchClientConfig;
