import React, { useState, useEffect } from 'react';
import { Menu, Tooltip } from 'antd';
import { NavLink, useRouteMatch, useHistory, useLocation } from 'react-router-dom'; // import { ReactSVG } from 'react-svg';
import FeatherIcon from 'feather-icons-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { faBuilding, faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import {
    faGraduationCap,
    faList,
    faSackDollar,
    faTrophy,
    faUsers,
    faShuffle,
    faPeopleArrows,
    faLock,
    faBookOpen,
    faFileLines,
    faBriefcase,
    faMagnifyingGlassChart,
    faTicket,
    faChalkboardTeacher,
    faUserPlus,
    faExternalLinkAlt
} from '@fortawesome/free-solid-svg-icons';
import propTypes from 'prop-types';
import { Helmet } from 'react-helmet';
// import { NavTitle } from './style';
// import versions from '../demoData/changelog.json';
import useFetchUserInfo from '../api/FetchUserInfo';
import useFetchSsoTokenInternship from '../api/FetchSsoTokenInternship';
import useFetchSsoTokenLearning from '../api/FetchSsoTokenLearning';
import './layout.css';

const { SubMenu } = Menu;
function MenuItems( { darkMode, toggleCollapsed, topMenu, clientConfig } ) {
    const { path } = useRouteMatch();
    const location = useLocation(); // Use useLocation to get the current path
    const pathName = location.pathname;

    const mainPath = pathName.split( path )[1]?.split( '/' )[1] || 'dashboard'; // Extract main path for openKeys

    const refreshToken = localStorage.getItem( 'refreshToken' );
    const history = useHistory();

    const [userRole, setUserRole] = useState();
    const { response: userInfo, FetchUserInfo } = useFetchUserInfo();
    const { response: ssotokenInternship, FetchSsoTokenInternship } = useFetchSsoTokenInternship();
    const { response: ssotokenLearning, FetchSsoTokenLearning } = useFetchSsoTokenLearning();

    useEffect( () => {
        if ( refreshToken ) {
            FetchUserInfo( refreshToken );
        }
    }, [refreshToken] );

    useEffect( () => {
        if ( userInfo ) {
            const userRole = userInfo?.data?.data?.user_role;
            const firstName = userInfo?.data?.data?.first_name || '';
            const lastName = userInfo?.data?.data?.last_name || '';
            const email = userInfo?.data?.data?.email || '';

            setUserRole( userRole );
            localStorage.setItem( 'userRole', userRole );
            localStorage.setItem( 'userName', `${ firstName }${ lastName }` ); // Template literal
            localStorage.setItem( 'userEmail', email );
        }
    }, [userInfo] );

    const [openKeys, setOpenKeys] = useState( !topMenu ? [`${ mainPath }`, 'tables'] : [] );

    // console.log( 'openkeys', openKeys );

    const onOpenChange = ( keys ) => {
        setOpenKeys( keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys );
    };

    const onClick = ( item ) => {
        if ( item.keyPath.length === 1 ) setOpenKeys( [] );
    };

    const handleGoToLearnings = () => {
        if ( ssotokenLearning && ssotokenLearning?.data?.data?.jwt ) {
            window.open(
                `https://experiment-labs-masters.web.app/preDashboard/?token=${ ssotokenLearning?.data?.data?.jwt }&org=strideahead`,
            );
        }
    };

    const handleGoToInternships = () => {
        if ( ssotokenInternship && ssotokenInternship?.data?.data?.jwt ) {
            window.open(
                `https://experimentlabsinternshipportal.web.app/preDashboard/?org=strideahead&token=${ ssotokenInternship?.data?.data?.jwt }`,
            );
        }
    };

    useEffect( () => {
        if ( !clientConfig?.client_id ) {
            return;
        }
        FetchSsoTokenInternship( refreshToken, clientConfig?.client_id );
        FetchSsoTokenLearning( refreshToken, clientConfig?.client_id );
    }, [clientConfig] );

    // Set defaultSelectedKeys and defaultOpenKeys based on the current path
    const getDefaultSelectedKeys = () => {
        const pathSplit = pathName.split( path )[1]?.split( '/' );
        // console.log( 'pathSplit', pathSplit );

        if ( pathSplit?.length === 1 ) {
            return ['home'];
        }

        if ( pathSplit?.length === 2 ) {
            return [pathSplit[1]];
        }

        if ( pathSplit?.length === 3 ) {
            return [pathSplit[2]];
        }

        if ( pathSplit?.length >= 4 ) {
            return [pathSplit[1]];
        }

        return [pathSplit[2]];
    };

    console.log( 'getDefaultSelectedKeys()', getDefaultSelectedKeys() );

    const getDefaultOpenKeys = () => {
        const pathSplit = pathName.split( path )[1]?.split( '/' );
        return pathSplit?.length > 1 ? [pathSplit[1]] : ['dashboard'];
    };

    console.log( 'getDefaultOpenKeys()', getDefaultOpenKeys() );

    useEffect( () => {
        getDefaultSelectedKeys();
        getDefaultOpenKeys();
    }, [pathName] );

    return (
        userRole && (
            <Menu
                onOpenChange={ onOpenChange }
                onClick={ onClick }
                mode={ !topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal' }
                theme={ darkMode && 'dark' }
                defaultSelectedKeys={ getDefaultSelectedKeys() } // Select the correct tab based on the URL
                defaultOpenKeys={ getDefaultOpenKeys() } // Open the correct submenu based on the URL
                overflowedIndicator={ <FeatherIcon icon="more-vertical" /> }
                openKeys={ openKeys }
            >
                { userRole === 'superadmin' || userRole === 'user' ? (
                    <>
                        <SubMenu
                            key="table1"
                            icon={ !topMenu && <FontAwesomeIcon icon={ faUsers } size="5x" style={ { color: '#adb4d2' } } /> }
                            title="Client"
                        >
                            <Menu.Item key="clientDetails">
                                <NavLink onClick={ toggleCollapsed } to={ `${ path }/allClients` }>
                                    List of Clients
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="addClients">
                                <NavLink onClick={ toggleCollapsed } to={ `${ path }/addClient/new` }>
                                    Add a Client
                                </NavLink>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu
                            key="table2"
                            icon={ !topMenu && <FontAwesomeIcon icon={ faBuilding } size="5x" style={ { color: '#adb4d2' } } /> }
                            title="Institutes"
                        >
                            <Menu.Item key="instituteDetails">
                                <NavLink onClick={ toggleCollapsed } to={ `${ path }/institutes` }>
                                    List of Institutes
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="addInstitutes">
                                <NavLink onClick={ toggleCollapsed } to={ `${ path }/institutes/new/info` }>
                                    Add a Institute
                                </NavLink>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu
                            key="table3"
                            icon={ !topMenu && <FontAwesomeIcon icon={ faGraduationCap } size="5x" style={ { color: '#adb4d2' } } /> }
                            title="Students"
                        >
                            <Menu.Item key="studentDetails">
                                <NavLink onClick={ toggleCollapsed } to={ `${ path }/students` }>
                                    List of Students
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="addStudent">
                                <NavLink onClick={ toggleCollapsed } to={ `${ path }/students/new/info` }>
                                    Add a Student
                                </NavLink>
                            </Menu.Item>
                        </SubMenu>
                        {/*
            <SubMenu key="table4" icon={ !topMenu && <FeatherIcon icon="cpu" /> } title="Events">
              <Menu.Item key="eventDetails">
                <NavLink onClick={ toggleCollapsed } to={ `${ path }` }>
                  List of Events
                </NavLink>
              </Menu.Item>
              <Menu.Item key="addEvents">
                <NavLink onClick={ toggleCollapsed } to={ `${ path }` }>
                  Add a Event
                </NavLink>
              </Menu.Item>
            </SubMenu>
            */}
                        {/*

            <SubMenu key="table5" icon={ !topMenu && <FeatherIcon icon="cpu" /> } title="Landing Page">
              <Menu.Item key="lpDetails">
                <NavLink onClick={ toggleCollapsed } to={ `${ path }` }>
                  List of Landing Page
                </NavLink>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="table6" icon={ !topMenu && <FeatherIcon icon="cpu" /> } title="Quiz">
              <Menu.Item key="uploadCsv">
                <NavLink onClick={ toggleCollapsed } to={ `${ path }` }>
                  Upload CSV for Quiz
                </NavLink>
              </Menu.Item>
              <Menu.Item key="quizDetails">
                <NavLink onClick={ toggleCollapsed } to={ `${ path }` }>
                  List of Quizes
                </NavLink>
              </Menu.Item>
            </SubMenu>
            */}

                        {/*
            <Menu.Item key="table5" title="Forms" icon={ !topMenu && <FeatherIcon icon="cpu" /> }>
              <NavLink to={ `${ path }/allForms` }>
                Forms
              </NavLink>
            </Menu.Item>
            */}

                        <SubMenu
                            key="table4"
                            icon={ !topMenu && <FontAwesomeIcon icon={ faList } size="5x" style={ { color: '#adb4d2' } } /> }
                            title="Forms & Leads"
                        >
                            <Menu.Item key="allforms">
                                <NavLink onClick={ toggleCollapsed } to={ `${ path }/allForms` }>
                                    All Forms
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="formsandleads">
                                <NavLink onClick={ toggleCollapsed } to={ `${ path }/leads` }>
                                    { userRole === 'superadmin' ? 'Client Forms' : 'Leads' }
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="customFlow">
                                <NavLink onClick={ toggleCollapsed } to={ `${ path }/flow/new` }>
                                    Custom Flow
                                </NavLink>
                            </Menu.Item>
                            {/*
              <Menu.Item key='uplaodForm' >
                <NavLink onClick={ toggleCollapsed } to={ `${ path }` }>
                  Upload Form
                </NavLink>
              </Menu.Item>
              */}
                            <Menu.Item key="transferForm">
                                <NavLink onClick={ toggleCollapsed } to={ `${ path }/leads/transfer` }>
                                    Transfer Form
                                </NavLink>
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="table5"
                            icon={ !topMenu && <FontAwesomeIcon icon={ faSackDollar } size="5x" style={ { color: '#adb4d2' } } /> }
                            title="Payments"
                        >
                            <Menu.Item key="promoCodes">
                                <NavLink onClick={ toggleCollapsed } to={ `${ path }/promo_codes` }>
                                    Promo Codes
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="addEvents">
                                <NavLink onClick={ toggleCollapsed } to={ `${ path }/transactions` }>
                                    Transactions
                                </NavLink>
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="table6"
                            icon={ !topMenu && <FontAwesomeIcon icon={ faTrophy } size="5x" style={ { color: '#adb4d2' } } /> }
                            title="Events"
                        >
                            <Menu.Item key="leaderboard">
                                <NavLink onClick={ toggleCollapsed } to={ `${ path }/leaderboard` }>
                                    Leaderboard
                                </NavLink>
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="table7"
                            icon={ !topMenu && <FontAwesomeIcon icon={ faShuffle } size="5x" style={ { color: '#adb4d2' } } /> }
                            title="UTM"
                        >
                            <Menu.Item key="viewUTM">
                                <NavLink onClick={ toggleCollapsed } to={ `${ path }/utm` }>
                                    View UTM
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="createUTM">
                                <NavLink onClick={ toggleCollapsed } to={ `${ path }/utm/create` }>
                                    Create UTM
                                </NavLink>
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="table8"
                            icon={ !topMenu && <FontAwesomeIcon icon={ faPeopleArrows } size="5x" style={ { color: '#adb4d2' } } /> }
                            title="Bookings"
                        >
                            <Menu.Item key="viewBookings">
                                <NavLink onClick={ toggleCollapsed } to={ `${ path }/bookings` }>
                                    All Bookings
                                </NavLink>
                            </Menu.Item>
                        </SubMenu>
                        <Menu.Item key="viewLearnings" icon={ <FontAwesomeIcon icon={ faBookOpen } /> } onClick={ handleGoToLearnings }>
                            Your Learnings
                        </Menu.Item>

                        <SubMenu
                            key="table9"
                            icon={ !topMenu && <FontAwesomeIcon icon={ faTicket } size="5x" style={ { color: '#adb4d2' } } /> }
                            title="Exam Coupons"
                        >
                            <Menu.Item key="listCouponCode">
                                <NavLink onClick={ toggleCollapsed } to={ `${ path }/coupon_code/all` }>
                                    List of Exam coupons
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="createCouponCode">
                                <NavLink onClick={ toggleCollapsed } to={ `${ path }/coupon_code/new` }>
                                    Add an Exam coupon
                                </NavLink>
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="table10"
                            icon={ !topMenu && <FontAwesomeIcon icon={ faChalkboardTeacher } size="5x" style={ { color: '#adb4d2' } } /> }
                            title="Mentor"
                        >
                            <Menu.Item key="listCouponCode">
                                <NavLink onClick={ toggleCollapsed } to={ `${ path }/mentor` }>
                                    List of Mentors
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="createCouponCode">
                                <NavLink onClick={ toggleCollapsed } to={ `${ path }/mentor/new` }>
                                    Add a Mentor
                                </NavLink>
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="test_series"
                            icon={ !topMenu && <FontAwesomeIcon icon={ faList } size="5x" style={ { color: '#adb4d2' } } /> }
                            title="Test Series"
                        >
                            <Menu.Item key="test_series">
                                <NavLink onClick={ toggleCollapsed } to={ `${ path }/test_series` }>
                                    View Test Series
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="edit_test_series">
                                <NavLink onClick={ toggleCollapsed } to={ `${ path }/test_series/edit_test_series` }>
                                    Create Test Series
                                </NavLink>
                            </Menu.Item>
                        </SubMenu>
                    </>
                ) : userRole === 'admin' ? (
                    <>
                        <Menu.Item
                            key="home"
                            icon={
                                !topMenu && <FontAwesomeIcon icon={ faMagnifyingGlassChart } size="5x" style={ { color: '#adb4d2' } } />
                            }
                        >
                            <NavLink to={ `${ path }` }>Overview</NavLink>
                        </Menu.Item>

                            { clientConfig?.client_id === 4 && (
                                <>
                                    <SubMenu
                                        key="table2"
                                        icon={ !topMenu && <FontAwesomeIcon icon={ faTrophy } size="5x" style={ { color: '#adb4d2' } } /> }
                                        title="Events"
                                    >
                                        <Menu.Item key="leaderboard">
                                            <NavLink onClick={ toggleCollapsed } to={ `${ path }/leaderboard` }>
                                                Leaderboard
                                            </NavLink>
                                        </Menu.Item>
                                    </SubMenu>
                                </>
                            ) }
                            <SubMenu
                                key="institutes"
                                icon={ !topMenu && <FontAwesomeIcon icon={ faBuilding } size="5x" style={ { color: '#adb4d2' } } /> }
                                title="Institutes"
                            >
                                <Menu.Item key="institutes">
                                    <NavLink onClick={ toggleCollapsed } to={ `${ path }/institutes` }>
                                        List of Institutes
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="new">
                                    <NavLink onClick={ toggleCollapsed } to={ `${ path }/institutes/new/info` }>
                                        Add a Institute
                                    </NavLink>
                                </Menu.Item>
                            </SubMenu>

                            <SubMenu
                                key="students"
                                icon={ !topMenu && <FontAwesomeIcon icon={ faUsers } size="5x" style={ { color: '#adb4d2' } } /> }
                                title="Users"
                            >
                                <Menu.Item key="students">
                                    <NavLink onClick={ toggleCollapsed } to={ `${ path }/students` }>
                                        List of Users
                                    </NavLink>
                                </Menu.Item>
                                {/* <Menu.Item key="addStudent">
                  <NavLink onClick={ toggleCollapsed } to={ `${ path }/students/new/info` }>
                    Add a User
                  </NavLink>
                </Menu.Item> */}
                            </SubMenu>

                            <Menu.Item
                                key="campaigns"
                                icon={ !topMenu && <FontAwesomeIcon icon={ faFileLines } size="5x" style={ { color: '#adb4d2' } } /> }
                            >
                                <NavLink to={ `${ path }/campaigns` }>Campaigns</NavLink>
                            </Menu.Item>

                            <SubMenu
                                key="test_series"
                                icon={ !topMenu && <FontAwesomeIcon icon={ faList } size="5x" style={ { color: '#adb4d2' } } /> }
                                title="Test Series"
                            >
                                <Menu.Item key="test_series">
                                    <NavLink onClick={ toggleCollapsed } to={ `${ path }/test_series` }>
                                        View Test Series
                                    </NavLink>
                                </Menu.Item>
                            </SubMenu>

                            <SubMenu
                                key="utm"
                                icon={ !topMenu && <FontAwesomeIcon icon={ faShuffle } size="5x" style={ { color: '#adb4d2' } } /> }
                                title="UTM"
                            >
                                <Menu.Item key="utm">
                                    <NavLink onClick={ toggleCollapsed } to={ `${ path }/utm` }>
                                        View UTM
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="create">
                                    <NavLink onClick={ toggleCollapsed } to={ `${ path }/utm/create` }>
                                        Create UTM
                                    </NavLink>
                                </Menu.Item>
                            </SubMenu>

                            { clientConfig?.client_id === 51 && (
                                <>
                                    <Menu.Item
                                        key="bookings"
                                        icon={ !topMenu && <FontAwesomeIcon icon={ faCalendarDays } size="5x" style={ { color: '#adb4d2' } } /> }
                                    >
                                        <NavLink to={ `${ path }/bookings` }>Sessions Booked</NavLink>
                                    </Menu.Item>
                                </>
                            ) }

                            { clientConfig?.client_id === 34 && (
                                <Tooltip title="Booking Feature is Locked" showArrow={ false } placement="right">
                                    <Menu.Item
                                        key="bookings"
                                        icon={ !topMenu && <FontAwesomeIcon icon={ faLock } size="2xl" style={ { color: '#FFD43B' } } /> }
                                        style={ { cursor: 'not-allowed' } }
                                    >
                                        {/*
                  <NavLink to={ `${ path }/bookings` } style={{cursor : 'not-allowed'}}>
                  */}
                                        Sessions Booked
                                        {/*
                  </NavLink>
                  */}
                                    </Menu.Item>
                                </Tooltip>
                            ) }

                            { clientConfig?.client_id === 51 && (
                                <>
                                    <SubMenu
                                        key="table9"
                                        className="custom-submenu"
                                        icon={ !topMenu && <FontAwesomeIcon icon={ faBookOpen } size="5x" style={ { color: '#adb4d2' } } /> }
                                        title="Your Learnings"
                                        onTitleClick={ handleGoToLearnings }
                                    />

                                    <SubMenu
                                        key="table10"
                                        className="custom-submenu"
                                        icon={ !topMenu && <FontAwesomeIcon icon={ faBriefcase } size="5x" style={ { color: '#adb4d2' } } /> }
                                        title="Internships"
                                        onTitleClick={ handleGoToInternships }
                                    />
                                </>
                            ) }

                            {/*
              <SubMenu key="table2" icon={ !topMenu && <FeatherIcon icon="cpu" /> } title="Events">
                <Menu.Item key="leaderboard">
                  <NavLink onClick={ toggleCollapsed } to={ `${ path }/leaderboard` }>
                    Leaderboard
                  </NavLink>
                </Menu.Item>
              </SubMenu>
              */}

                            <SubMenu
                                key="coupon_code"
                                icon={ !topMenu && <FontAwesomeIcon icon={ faTicket } size="5x" style={ { color: '#adb4d2' } } /> }
                                title="Exam Coupons"
                            >
                                <Menu.Item key="all">
                                    <NavLink onClick={ toggleCollapsed } to={ `${ path }/coupon_code/all` }>
                                        List of Exam coupons
                                    </NavLink>
                                </Menu.Item>

                                <Menu.Item key="new">
                                    <NavLink onClick={ toggleCollapsed } to={ `${ path }/coupon_code/new` }>
                                        Add an Exam coupon
                                    </NavLink>
                                </Menu.Item>
                            </SubMenu>

                            <SubMenu
                                key="mentor"
                                icon={ !topMenu && <FontAwesomeIcon icon={ faChalkboardTeacher } size="5x" style={ { color: '#adb4d2' } } /> }
                                title="Mentor"
                            >
                                <Menu.Item key="mentor">
                                    <NavLink onClick={ toggleCollapsed } to={ `${ path }/mentor` }>
                                        List of Mentors
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="new">
                                    <NavLink onClick={ toggleCollapsed } to={ `${ path }/mentor/new` }>
                                        Add a Mentor
                                    </NavLink>
                                </Menu.Item>
                            </SubMenu>

                            <Menu.Item
                                key="bookingsdashboard"
                                icon={ !topMenu && <FontAwesomeIcon icon={ faUserPlus } size="lg" style={ { color: '#adb4d2' } } /> }
                            >
                                <button
                                    type='button'
                                    onClick={ () => window.open( 'https://bookings.strideahead.io/auth/login', '_blank' ) }
                                    style={ {
                                        background: 'none',
                                        border: 'none',
                                        color: 'inherit',
                                        fontSize: 'inherit',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                    } }
                                >
                                    Bookings Dashboard
                                    <FontAwesomeIcon icon={ faExternalLinkAlt } size="sm" style={ { marginLeft: 33, color: '#868EAE' } } />
                                </button>
                            </Menu.Item>

                        </>
                    ) : userRole === 'student' ? (
                        <>
                            <Menu.Item
                                key="home"
                                icon={
                                    !topMenu && <FontAwesomeIcon icon={ faMagnifyingGlassChart } size="5x" style={ { color: '#adb4d2' } } />
                                }
                            >
                                <NavLink to={ `${ path }` }>Overview</NavLink>
                            </Menu.Item>

                                <Menu.Item
                                    key="myresponses"
                                    icon={ !topMenu && <FontAwesomeIcon icon={ faFileLines } size="5x" style={ { color: '#adb4d2' } } /> }
                                >
                                    <NavLink to={ `${ path }/myresponses` }>Assessments</NavLink>
                                </Menu.Item>

                                {
                                    clientConfig?.client_id === 57
                                        ?
                                        <Menu.Item
                                            key="viewInternships"
                                            style={ { cursor: 'not-allowed' } }
                                            icon={
                                                !topMenu && <FontAwesomeIcon icon={ faCalendarDays } size="5x" style={ { color: '#adb4d2', opacity: 0.6 } } />
                                            }
                                        >
                                            <Tooltip
                                                title="This feature is currently unavailable for your account."
                                                placement="right"
                                            >
                                                <span style={ { color: '#adb4d2', cursor: 'not-allowed' } }>Sessions Booked</span>
                                            </Tooltip>
                                        </Menu.Item>
                                        :
                                        <Menu.Item
                                            key="bookings"
                                            icon={ !topMenu && <FontAwesomeIcon icon={ faCalendarDays } size="5x" style={ { color: '#adb4d2' } } /> }
                                        >
                                            <NavLink to={ `${ path }/bookings` }>Sessions Booked</NavLink>
                                        </Menu.Item>

                                }

                                { clientConfig?.client_id === 51 && (
                                    <>
                                        {/* <SubMenu
                                            key="viewLearnings"
                                            className="custom-submenu"
                                            icon={ !topMenu && <FontAwesomeIcon icon={ faBookOpen } size='5x' style={ { color: "#adb4d2" } } /> }
                                            title="Your Learnings"
                                            onTitleClick={ handleGoToLearnings } />

                                        <SubMenu
                                            key="viewInternships"
                                            className="custom-submenu"
                                            icon={ !topMenu && <FontAwesomeIcon icon={ faBriefcase } size='5x' style={ { color: "#adb4d2" } } /> }
                                            title="Internships"
                                            onTitleClick={ handleGoToInternships } /> */}
                                        <Menu.Item
                                            key="viewLearnings"
                                            style={ { cursor: 'not-allowed' } }
                                            icon={
                                                !topMenu && (
                                                    <FontAwesomeIcon
                                                        icon={ faLock }
                                                        size="5x"
                                                        style={ { color: '#adb4d2', opacity: 0.6, cursor: 'not-allowed' } }
                                                    />
                                                )
                                            }
                                        >
                                            <Tooltip
                                                title="This feature is currently unavailable for your account. Please contact your admin to know more."
                                                placement="right"
                                            >
                                                <span style={ { color: '#adb4d2', cursor: 'not-allowed' } }>Your Learnings</span>
                                            </Tooltip>
                                        </Menu.Item>
                                        <Menu.Item
                                            key="viewInternships"
                                            style={ { cursor: 'not-allowed' } }
                                            icon={
                                                !topMenu && (
                                                    <FontAwesomeIcon
                                                        icon={ faLock }
                                                        size="5x"
                                                        style={ { color: '#adb4d2', opacity: 0.6, cursor: 'not-allowed' } }
                                                    />
                                                )
                                            }
                                        >
                                            <Tooltip
                                                title="This feature is currently unavailable for your account. Please contact your admin to know more."
                                                placement="right"
                                            >
                                                <span style={ { color: '#adb4d2', cursor: 'not-allowed' } }>Internships</span>
                                            </Tooltip>
                                        </Menu.Item>
                                    </>
                                ) }
                            </>
                        ) : userRole === 'institute' ? (
                            <>
                                <Menu.Item
                                    key="home"
                                    icon={
                                        !topMenu && <FontAwesomeIcon icon={ faMagnifyingGlassChart } size="5x" style={ { color: '#adb4d2' } } />
                                    }
                                >
                                    <NavLink to={ `${ path }` }>Overview</NavLink>
                                </Menu.Item>

                                    <SubMenu
                                        key="students"
                                        icon={ !topMenu && <FontAwesomeIcon icon={ faUsers } size="5x" style={ { color: '#adb4d2' } } /> }
                                        title="Users"
                                    >
                                        <Menu.Item key="students">
                                            <NavLink onClick={ toggleCollapsed } to={ `${ path }/students` }>
                                                List of Users
                                            </NavLink>
                                        </Menu.Item>
                                        {/* <Menu.Item key="addStudent">
                      <NavLink onClick={ toggleCollapsed } to={ `${ path }/students/new/info` }>
                        Add a User
                      </NavLink>
                    </Menu.Item> */}
                                    </SubMenu>

                                    <Menu.Item
                                        key="campaigns"
                                        icon={ !topMenu && <FontAwesomeIcon icon={ faFileLines } size="5x" style={ { color: '#adb4d2' } } /> }
                                    >
                                        <NavLink to={ `${ path }/campaigns` }>Campaigns</NavLink>
                                    </Menu.Item>

                                    { clientConfig?.client_id === 51 && (
                                        <>
                                            {/*
 <SubMenu
                                                key="viewInternships"
                                                className="custom-submenu"
                                                icon={ !topMenu && <FontAwesomeIcon icon={ faLock } size='5x' style={ { color: '#adb4d2', opacity: 0.6, cursor: 'not-allowed' } } /> }
                                                title="Internships"
                                            />
*/}
                                            <Menu.Item
                                                key="viewInternships"
                                                style={ { cursor: 'not-allowed' } }
                                                icon={
                                                    !topMenu && <FontAwesomeIcon icon={ faLock } size="5x" style={ { color: '#adb4d2', opacity: 0.6 } } />
                                                }
                                            >
                                                <Tooltip
                                                    title="This feature is currently unavailable for your account. Please contact your admin to know more."
                                                    placement="right"
                                                >
                                                    <span style={ { color: '#adb4d2', cursor: 'not-allowed' } }>Internships</span>
                                                </Tooltip>
                                            </Menu.Item>
                                        </>
                                    ) }

                                    {/* <SubMenu key="couponCode" icon={ !topMenu && <FontAwesomeIcon icon={ faTicket } size='5x' style={ { color: "#adb4d2" } } /> } title="Exam Code">

                    <Menu.Item key="listCouponCode">
                      <NavLink onClick={ toggleCollapsed } to={ `${ path }/coupon_code/all` }>
                        List of Exam Codes
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="createCouponCode">
                      <NavLink onClick={ toggleCollapsed } to={ `${ path }/coupon_code/new` }>
                        Add a Exam Code
                      </NavLink>
                    </Menu.Item>
                  </SubMenu> */}

                                    <SubMenu
                                        key="mentor"
                                        icon={ !topMenu && <FontAwesomeIcon icon={ faChalkboardTeacher } size="5x" style={ { color: '#adb4d2' } } /> }
                                        title="Mentor"
                                    >
                                        <Menu.Item key="mentor">
                                            <NavLink onClick={ toggleCollapsed } to={ `${ path }/mentor` }>
                                                List of Mentors
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="new">
                                            <NavLink onClick={ toggleCollapsed } to={ `${ path }/mentor/new` }>
                                                Add a Mentor
                                            </NavLink>
                                        </Menu.Item>
                                    </SubMenu>
                                </>
                            ) : userRole === 'mentor' ? (
                                <>
                                    <SubMenu
                                        key="home"
                                        className="custom-submenu"
                                        icon={
                                            !topMenu && <FontAwesomeIcon icon={ faMagnifyingGlassChart } size="5x" style={ { color: '#adb4d2' } } />
                                        }
                                        title="Overview"
                                        onTitleClick={ () => history.push( `${ path }` ) }
                                    />

                                        <SubMenu
                                            key="students"
                                            icon={ !topMenu && <FontAwesomeIcon icon={ faUsers } size="5x" style={ { color: '#adb4d2' } } /> }
                                            title="Users"
                                        >
                                            <Menu.Item key="students">
                                                <NavLink onClick={ toggleCollapsed } to={ `${ path }/students` }>
                                                    List of Users
                                                </NavLink>
                                            </Menu.Item>
                                        </SubMenu>

                        <Menu.Item
                            key="campaigns"
                            icon={ !topMenu && <FontAwesomeIcon icon={ faFileLines } size="5x" style={ { color: '#adb4d2' } } /> }
                        >
                            <NavLink to={ `${ path }/campaigns` }>Campaigns</NavLink>
                        </Menu.Item>
                    </>
                ) : (
                    <></>
                ) }
                <Helmet>
                    <link rel="icon" href={ clientConfig.favicon } />
                </Helmet>
            </Menu>
        )
    );
}

MenuItems.propTypes = {
    darkMode: propTypes.bool,
    topMenu: propTypes.bool,
    toggleCollapsed: propTypes.func,
    clientConfig: propTypes.object.isRequired,
    // events: propTypes.object,
};

const mapStateToProps = ( state ) => {
    return {
        clientConfig: state.clientConfig,
    };
};

export default connect( mapStateToProps )( MenuItems );
