import Styled from 'styled-components';
import { Input, Button, Row, Carousel } from 'antd';

const Testimonial = Styled.div `
display : flex;
  padding: 25px;
`;

const UserName = Styled.p `
  font-weight: 500;
  font-size : 20px;
  color : white;
  margin: 0px;
`;

const UserDesignation = Styled.p `
  font-weight: 300;
  font-size : 17px;
  color : white;
  margin: 0px;
`;

const Author = Styled.p `
  font-weight: 800;
  font-size : 20px;
  color : white;
  margin: 0px;
`;

const TestimonialWrapper = Styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DetailsWrapper = Styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 25px;
  gap: 15px;
`;

const UserDetails = Styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FullPageGradient = Styled.div `
  height: 100vh;
  background-color: #f1f3f5; 
  background-image: white;
  `;


const CarouselWrapper = Styled.div `
  width: 80%;
  padding: 15px;
  border-radius: 10px;
`;

const CustomCarousel =
    Styled(Carousel)
`
.slick-dots li button {
  color: white;
  background-color: white;
  margin-top: 3rem;
  bottom : 0px;
}

.slick-dots li.slick-active button {
  background-color: white;
  color: white;
  bottom : 0px;
}
`;


const FullRowGradient = Styled(Row)
`
  display: flex;
  align-content:center;
  justify-content:center;
  align-items:center;
`;

const Aside = Styled.div `
  position: relative;
  background: linear-gradient(to right, #000046, #1CB5E0); /* Replace with your gradient colors */
  height: 100vh; /* Full height */
  color: white; /* Text color */

  .auth-side-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .topShape,
  .bottomShape {
    position: absolute;
    z-index: 1;
  }

  .topShape {
    top: 0;
    left: 0;
  }

  .bottomShape {
    bottom: 0;
    right: 0;
  }
`;

const Content = Styled.div `
  z-index: 2;
  position: relative;
`;

const AuthWrapper = Styled.div `
  height: 100%;
  padding: 40px;
  @media only screen and (max-width: 1599px){
    padding: 25px;
  }

  @media only screen and (max-width: 767px){
    text-align: center;
  }
  .auth-notice{
    text-align: right;
    font-weight: 500;
    color: ${ ( { theme } ) => theme['gray-color'] };
    @media only screen and (max-width: 767px){
      text-align: center;
      margin-bottom: 10px;
    }
  }
  button{
    &.btn-signin{
      min-width: 185px;
    }
    &.btn-create{
      border-radius: 8px;
      min-width: 205px;
    }
    &.btn-reset{
      border-radius: 8px;
      min-width: 260px;
    }
    &.ant-btn-lg{
      font-size: 14px;
      font-weight: 500;
      height: 48px;
    }
  }
  .auth-contents{
    display: flex;
    align-items: center;
    justify-content: center;
    form{
      width: 420px;
      h1{
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 45px;
        @media only screen and (max-width: 767px){
          margin-bottom: 28px;
        }
        input::placeholder{
          color: ${ ( { theme } ) => theme['extra-light-color'] };
        }
      }
      .auth-form-action{
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 379px){
          flex-flow: column;
          .forgot-pass-link{
            margin-top: 15px;
          }
        }
      }
    }
    #forgotPass{
      .forgot-text{
        margin-bottom: 25px;
      }
      .return-text{
        margin-top: 35px;
      }
    }
    .form-divider{
      font-size: 13px;
      color: ${ ( { theme } ) => theme['gray-solid'] };
      text-align: center;
      position: relative;
      margin-bottom: 25px;
      &:before{
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        height: 1px;
        background: ${ ( { theme } ) => theme['border-color-light'] };
      }
      span{
        background: #fff;
        padding: 0 15px;
        display: inline-block;
        position: relative;
        z-index: 2;
      }
    }
    .social-login{
      display: flex;
      align-items: center;
      margin: -6px -6px 14px -6px;
      @media only screen and (max-width: 767px){
        justify-content: center;
      }
      &.signin-social{
        li{
          a{
            box-shadow: 0 5px 15px ${ ( { theme } ) => theme['light-color'] }10;
            background-color: #fff;
          }
        }
      }
      li{
        padding:6px;
        a{
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          height: 48px;
          padding: 0 15px;
          background: ${ ( { theme } ) => theme['bg-color-light'] };
          color: ${ ( { theme } ) => theme['text-color'] };
          font-weight: 500;
          @media only screen and (max-width: 379px){
            height: 44px;
            padding: 0 12px;
          }
          span:not(.anticon){
            display: inline-block;
            margin-left: 5px;
          }
          svg,
          i{
            width: 20px;
            height: 20px;
          }
          &.google-signup,
          &.google-signin{
            display: flex;
            align-items: center;
            padding: 0 30px;
            @media only screen and (max-width: 379px){
              padding: 0 5px;
            }
            img{
              margin-right: 8px;
              @media only screen and (max-width: 379px){
                margin-right: 4px;
              }
            }
          }
          &.facebook-sign{
            color: #475993;
          }
          &.twitter-sign{
            color: #03A9F4;
          }
        }
      }
    }
    .auth0-login{
      margin: -6px;
      display: flex;
      flex-wrap: wrap;
      a{
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          height: 48px;
          padding: 0 30px;
          background: ${ ( { theme } ) => theme['bg-color-light'] };
          color: ${ ( { theme } ) => theme['text-color'] };
          font-weight: 500;
          border: 0 none;
          border-radius: 5px;
          margin: 6px;
          flex: 1;
          @media (max-width:480px){
            flex: none;
            width: 100%;
          }
      }
    }
  }
  .auth0-login{
      margin: -6px;
      display: flex;
      flex-wrap: wrap;
      a{
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          height: 48px;
          padding: 0 30px;
          background: ${ ( { theme } ) => theme['bg-color-light'] };
          color: ${ ( { theme } ) => theme['text-color'] };
          font-weight: 500;
          border: 0 none;
          border-radius: 5px;
          margin: 6px;
          flex: 1;
          @media (max-width:480px){
            flex: none;
            width: 100%;
          }
      }
    }
  }
`;

const AuthWrapperTwo = Styled.div `
  display: flex;
  flex-direction : column;
  justify-content: center;
  background-color: #f1f3f5; 
  width : 70%;
  margin-left: 10rem;
  background: linear-gradient(135deg, #p3a5b9, #f6f1d3);
`;

const AuthWrapperOne = Styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin : 3rem;
  height: 88vh;
  align-items: center;
  background-color: #061069;
  border-radius: 8px;
`;

const HeadingWrapper = Styled.div `
  display: flex;
  flex-direction : column;
  justify-content: center;
  align-items: flex-start;
`;

const AuthContent = Styled.div `
  padding: 50px;
  padding-left: 0px;
  padding-bottom : 15px;
`;

const FormHeading = Styled.h3 `
  font-size: 30px;
  text-align: center;
  color : black;
  margin : 0px;
`;

const FormsubHeading = Styled.h3 `
  font-size: 15px;
  text-align: center;
  color : black;
`;

const SubHeading = Styled.h4 `
  font-size: 15px;
  font-weight:300;
  margin-bottom: 3px;
  text-align: center;
`;

const CustomInput = Styled(Input)
`
  border-radius: 4px;
`;

const SignInButton = Styled(Button)
`
  width: 100%;
  background-color: #061069;
  color: #ffffff;
  border: none;
  height: 40px;
  font-size: 16px;
  border-radius: 4px;

  &:hover {
    background-color: black;
    color: #fff;
  }
`;

export { FormsubHeading, AuthWrapperOne, HeadingWrapper, UserDesignation, UserName, UserDetails, DetailsWrapper, TestimonialWrapper, AuthContent, FormHeading, AuthWrapperTwo, CustomInput, SignInButton, Aside, Content, AuthWrapper, FullPageGradient, SubHeading, CarouselWrapper, CustomCarousel, FullRowGradient, Testimonial, Author };